.register {
  width: 100vw;
  height: 100vh;
  background: linear-gradient(
      to top,
      rgba(7, 14, 23, 0.7) 50%,
      rgb(7, 14, 23, 1) 100%
        ),
    url("https://www.hdplayonline.com/assets/img/fondo2.jpg");
  background-size: cover;
  position: relative;

  .top {
    .wrapper {
      padding: 20px 50px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .logo {
        height: 60px;
      }
    }
  }

  .container {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;

    form {
      width: 233px;
      height: 400px;
      padding: 30px;
      padding-right: 50px;
      padding-left: 50px;
      border-radius: 5px;
      background-color: var(--main-color);
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      input {
        height: 40px;
        border-radius: 5px;
        background-color: gray;
        color: white;
        padding-left: 10px;
        margin-bottom: 10px;

        &::placeholder {
          color: rgb(10, 10, 10);
        }
      }

      button {
        height: 40px;
        border-radius: 5px;
        margin-top: 10px;
        color: white;
        border: none;
        font-size: 18px;
        font-weight: 500;
        cursor: pointer;
        background: linear-gradient(180deg,#2e57ab 10%,#9b60d6);
      }

      span {
        color: rgb(36, 33, 33);
        b {
          color: white;
        }
      }
    }
  }
}

.featured {
  height: 80vh;
  background: linear-gradient(
      to top,
      rgba(7, 14, 23, 0.7) 50%,
      rgb(7, 14, 23, 1) 100%
  ),
    url("https://www.hdplayonline.com/assets/img/fondo2.jpg");
  background-size: cover;
  position: relative;

  .category {
    position: absolute;
    top: 80px;
    left: 50px;
    font-size: 30px;
    font-weight: 500;
    color: white;
    display: flex;
    align-items: center;

    select {
      cursor: pointer;
      background-color: var(--main-color);
      border: 1px solid white;
      color: white;
      margin-left: 20px;
      padding: 5px;
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .info {
    width: 60%;
    position: absolute;
    left: 50px;
    bottom: 25%;
    color: white;
    display: flex;
    flex-direction: column;

    img {
      width: 20%;
    }

    .desc {
      margin: 1px 0px;
    }

    .buttons {
      display: flex;

      button {
        padding: 10px 20px;
        border: none;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        font-weight: 500;
        margin-right: 10px;
        cursor: pointer;

        &.play {
          background-color: white;
          color: var(--main-color);
        }

        &.more {
          background-color: gray;
          color: white;
        }

        span {
          margin-left: 5px;
        }
      }
    }
  }
}

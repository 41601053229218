.login {
    width: 100vw;
    height: 100vh;
    background: linear-gradient(
        to top,
        rgba(7, 14, 23, 0.7) 50%,
        rgb(40, 129, 170) 100%
      ),
      url("./fondo.jpg");
    background-size: cover;
    position: relative;
  
    .top {
      .wrapper {
        padding: 20px 50px;
        display: flex;
        align-items: center;
        justify-content: space-between;
  
        .logo {
          height: 250px;
          -webkit-filter: grayscale(1);
          filter: grayscale(2);
        }
      }
    }
  
    .container {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: white;
  
      form {
        width: 300px;
        height: 300px;
        padding: 30px;
        border-radius: 5px;
        background-color: var(--main-color);
        display: flex;
        flex-direction: column;
        justify-content: space-around;
  
        input {
          height: 40px;
          border-radius: 5px;
          background-color: gray;
          color: white;
          padding-left: 10px;
  
          &::placeholder {
            color: lightgray;
          }
        }
  
        button {
          height: 40px;
          border-radius: 5px;
  
          color: white;
          border: none;
          font-size: 18px;
          font-weight: 500;
          cursor: pointer;
          background: linear-gradient(180deg, #2881aa 10%, #0f0f0f 100%);
        }
  
        span {
          color: lightgray;
          b {
            color: white;
          }
        }
      }
    }
  }
  